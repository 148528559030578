import React from "react"
import Layout from "../components/layout"
import config from "../utils/siteConfig"
import Button from "../components/Atoms/Button/Button"
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faEye } from "@fortawesome/free-solid-svg-icons"

export default function Privacy({ data }) {
    debugger;
    debugger;
    const page = data.allContentfulZMaintenance.edges[0].node

    const Bold = ({ children }) => <span className="bold">{children}</span>
    //const Text = ({ children }) => <p className="align-center">{children}</p>;

    const options = {
        renderNode: {
            [BLOCKS.HEADING_1]: (node, children) => (
                <h1 className="heading1">{children}</h1>
            ),
            [BLOCKS.HEADING_2]: (node, children) => (
                <h2 className="heading2">{children}</h2>
            ),
            [BLOCKS.HEADING_3]: (node, children) => (
                <h3 className="heading3">{children}</h3>
            ),
            [BLOCKS.HEADING_4]: (node, children) => (
                <h4 className="heading4">{children}</h4>
            ),
            [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
                <img src={`https:${node.data.target.fields.file["en-US"].url}`} />
            ),
            [BLOCKS.PARAGRAPH]: (node, children) => (
                <p className="d-inline-block">{children}</p>
            ),
            [BLOCKS.UL_LIST]: (node, children) => {
                return (
                    <ul
                        style={{
                            listStyle: 'disc',
                            lineHeight: '35px',
                            marginLeft: '1rem',
                        }}
                    >
                        {children.map((item) => (
                            <li key={item.key}>{item.props.children[0].props.children[0]}</li>
                        ))}
                    </ul>
                );
            },
            //[BLOCKS.UL_LIST]: (node, children) => (
            //    <span className="d-inline-block bulletSpan">{children}</span>
            //),

        },
        renderMark: {
            [MARKS.BOLD]: text => <Bold>{text}</Bold>,
        },
        renderText: (text) =>
            text
                .replace(/\u2028/g, "")
                .split("\n")
                .flatMap((text, i) => [i > 0 && <br />, text])
    }

    const {
        id,
        metaData,
        mtHHd,
        mtHTxt,
        mtHRtxt,
    } = page


    return (
        <Layout
            IsHeaderImageDark={false}
            navColour={"white"}>
            <head>
                <title>{metaData.title}</title>
                <meta charset="utf-8" />
                <meta name="description" content={metaData.description} />
                <meta name="keywords" content={metaData.keywords.keywords} />
                <meta name="robots" content="noindex" />
            </head>
            <section>
                <div className="notFound-container">
                    <div className="row flex-column pl-3">
                        <div className="row">
                            <h1 className="h2-huge4 h1-darkBlue">
                                {mtHHd}
                            </h1>
                        </div>
                        <div className="row pt-3">
                            <p className="text-darkgray2 p-medium2">
                                {mtHTxt}
                            </p>
                        </div>
                        <div className="row pt-4">
                            <p className="text-darkgray2 p-small">
                                {documentToReactComponents(mtHRtxt.json, options)}
                            </p>
                        </div>
                    </div>
                </div>
            </section>


        </Layout >
    );
}

export const query = graphql`
query($locale: String!) {
    allContentfulZMaintenance(
        filter: {
        node_locale: { eq: $locale }
    }) {
        edges {
            node {
                id
                metaData {
                    slug
                    title
                    description
                    keywords {
                        keywords
                    }
                }
                mtHHd
                mtHTxt
                mtHRtxt {
                    json
                }
            }
        }
    }
}
`
